<template>
    <Setup-Step
        @next="f => f()"
        @yes="f => addNew()"
        @no="next"
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        :showYesNo="stage == 0"
        step="drivers">
        <template v-slot:title>
            <v-card-title>
                Drivers
                <v-spacer />
                <v-btn v-if="stage != 0" class="primary" dense @click="stage = 0" small>Start Again</v-btn>
            </v-card-title>
        </template>
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List
                        :canSearch="false"
                        navigation="users"
                        :onFilter="list => list.filter(x => x.isGlobalAdmin || x.roles.some(r => csvContains(r.permissions, 'driver')))"
                        :params="{ includeProperties: 'Roles' }"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.userName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List>
                    <p class="my-4">Would you like to add a driver?</p>
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Driver Name"
                        v-model="newItem.userName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Journey-Setup',
    components: {
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || {};
            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },

    }
}
</script>